.doc-text__match
  background-size .4rem 2px
  background-position 0 100%
  background-repeat repeat-x
  display inline
  font-weight bold
  padding 1px 0

.doc-text__match--conceptual
  background-image linear-gradient(to right, rgba(3, 150, 194, 0.8) 60%, rgba(3, 150, 194, 0) 60%)

.doc-text__match--exact
  background-image linear-gradient(to right, rgba(3, 150, 194, 0.8) 0%, rgba(3, 150, 194, 0.8) 100%)
