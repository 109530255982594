/*
** loading stripes
*/

loadingStripeSize = 60px

.loading-stripes
  fade-visible()
  absolute top left
  width 100%
  height 100%

  color = rgba(0, 0, 0, 0.2)
  background-size loadingStripeSize loadingStripeSize
  animation animate-stripes 2s linear infinite
  background-image linear-gradient(
    135deg,
    color 25%,
    transparent 25%,
    transparent 50%,
    color 50%,
    color 75%,
    transparent 75%,
    transparent
  )

@keyframes animate-stripes
  from
    background-position 0 0
  to
    background-position (0 - loadingStripeSize) 0

fade-transition()
  transition opacity .2s ease-in

.fade-enter
  opacity 0.01

.fade-enter-active
  fade-transition()
  opacity 1

.fade-appear
  opacity 0.01

.fade-appear-active
  fade-transition()
  opacity 1

.fade-exit
  opacity 1

.fade-exit-active
  fade-transition()
  opacity 0.01
