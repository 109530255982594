flexx(direction)
  display flex
  flex-direction direction

fade-visible()
  transition opacity 0.75s ease-in-out
  opacity 0

  &.visible
    opacity 1

fill-viewport()
  position absolute
  top 0
  bottom 0
  left 0
  right 0

// Centering techniques, straight from the W3C
// http://www.w3.org/Style/Examples/007/center.en.html

// center both vertically and horizontally inside closest positioned ancestor
center()
  absolute top 50% left 50%
  margin-right -50%
  transform translate(-50%, -50%)

square(size)
  display inline-block // can't set width and height without it
  width size
  height size

circle(size)
  square(size)
  border-radius 50%

rounded-corners()
  border-radius standardRadius

.err-text
  color errorTxt
  font-size 0.875rem
  font-style italic

link-color()
  color standardBtn

  &:hover
    color standardHover

  &:active
    color accentActive

link-text-decoration()
  text-decoration none

  &:hover
    text-decoration underline

hint-text()
  color textLight
  font-style italic

inset-shadow()
  box-shadow inset 0 1px 3px rgba(0, 0, 0, 0.3)

outset-shadow()
  box-shadow 0 1px 3px rgba(0, 0, 0, 0.3)

outset-shadow-heavy()
  box-shadow 0 3px 9px rgba(0, 0, 0, 0.5)

error-outline()
  border 1px solid errorTxt
  box-shadow 0 0 5px errorTxt
