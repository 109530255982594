.calendar
  display inline-block

.calendar__current-month
  flex-grow 1
  text-align center
  cursor default
  height 2rem

.calendar__week-name
  text-align center
  font-size 0.875rem
  height 1.5rem

.calendar__row
  height 2rem

.calendar__cell
  border 1px gray2 solid
  transition all .2s ease

  &:hover
    border 1px darken(standardHover, 20%) double

.calendar__cell--different-month
  border none

  &:hover
    border none

.calendar__cell--selected
  border 1px darken(standardBtn, 20%) double

.calendar__cell--inside-selected-range
  border 1px darken(standardBtn, 10%) double

.calendar__cell--disabled
  pointer-events none

.calendar__day-container
  position relative

.calendar__day-container--start
.calendar__day-container--end
  &:after
    // ensure that it is rendered above its surroundings
    z-index 1
    content ''
    position absolute
    margin-top -1px
    width 0.125rem
    height 100%
    background darken(standardBtn, 20%)
    border 1px darken(standardBtn, 20%) double

.calendar__day-container--start
  &:after
    left 0
    margin-left -0.25rem
    border-top-left-radius .25rem
    border-bottom-left-radius .25rem

.calendar__day-container--end
  &:after
    right 0
    margin-right -0.25rem
    border-top-right-radius .25rem
    border-bottom-right-radius .25rem

.calendar__day
  position relative
  border-radius 0
  box-shadow none
  height 2rem
  width 2rem
  text-align center

  &:hover
    background standardHover
    color white

  &:disabled
  &:disabled:hover
    background gray0
    color textLight

.calendar__day--selected
  background standardBtn
  color white

.calendar__day--inside-selected-range
  background lighten(standardBtn, 40%)
