.project-error-modal__header
  font-weight 600

.project-error-modal__body
  margin-bottom 1rem

.project-error-modal__footer
  text-align left

.project-error-modal__link
  button()
  height unset
  margin-right 0.5rem

  &:hover
    color white
