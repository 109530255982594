@require './variables.styl'
@require './mixins.styl'

.upload-page
  flexx(column)
  flex 1
  padding 2rem

.upload-page__preview-section
  flexx(column)
  flex-grow 1

.upload-page__preview
  outset-shadow()
  rounded-corners()
  position relative
  flex-grow 1

.upload-page__field--file
  display none

.upload-page__field--button
  button()
  padding 0.5rem
  height unset

.upload-page__result-container
  center()
  max-width 50%
  text-align center

.upload-page__result-msg
  margin 0.5rem 0
  font-size 0.875rem

.upload-page__result-msg--header
  font-size 1rem

.upload-page__result-msg--footer
  hint-text()
