.vertical-bar-chart
  display block
  width 100%
  height 2rem

.vertical-bar-chart__bar
  fill blue2

  &:last-child
    fill standardBtn
