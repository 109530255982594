input-with-inset-icon()
  position relative

  > input
    width 100%
    padding 0 2.5rem 0 workspace-gutter
    margin 0
    box-sizing border-box
    font-size 1rem

  > svg
    position absolute
    top .5rem
    right .75rem
    font-size 1em
    color standardBtn
    cursor pointer

    &:hover
      color standardHover

    &.disabled
      cursor auto
      color gray3

fieldset
  border none
  margin 0
  padding 0

label
  font-size .875rem

legend
  font-size .875rem
  color textLight

input
textarea
  inset-shadow()
  background-color white
  border none
  border-radius .125rem
  display block
  height 2rem
  margin .5rem 0
  padding .25rem .5rem

  &:disabled
    background gray0
    color textLight

  &.error
    border 1px red0
    box-shadow 0 0 2px 2px red0

.label
  color textLight
  font-size .875rem
  margin .5rem
  cursor default
  user-select none
