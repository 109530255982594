@require '../mixins'
@require '../variables'

button()
  outset-shadow()
  rounded-corners()
  appearance none
  background blue0
  color gray9
  cursor pointer
  display inline-block
  font-size 1rem
  height 2rem
  padding .5rem
  transition background-color .2s ease
  white-space nowrap

  &:hover
  &:active, &.active
    text-decoration none
    background blue1
    color gray9

  &.cancel, &.delete
    background-color cancelBtn

    &:hover
      background-color cancelHover

  &.create, &.add
    background-color createBtn

    &:hover
      background-color createHover

  &.alert, &.change
    padding 0 .5rem
    background-color alertBtn

    &:hover
      background-color alertHover

  &:disabled, &.disabled
    background gray0
    color textLight

    &:hover
      background gray0
      color textLight

button
  transition background-color .2s ease
  white-space nowrap
  background unset
  border unset
  padding unset
