@require './mixins.styl'

.upload-preview
  fill-viewport()
  margin 0 0.5rem
  overflow scroll

.upload-preview__message-container
  flexx(column)
  height 20rem
  justify-content center
  align-items center

.upload-preview__message
  hint-text()

.upload-preview__metadata-value
  display block
  overflow ellipsis

  // this provides vertical spacing when displaying multiple stacked values for a given field
  &:not(:last-child)
    margin-bottom 0.25rem
