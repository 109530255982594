@import 'nib'
@import '~normalize.css/normalize.css'
@import '~font-awesome/css/font-awesome.min.css'

@font-face
  font-family 'Lato'
  font-style normal
  font-weight 300
  src local('Lato Light'),
      local('Lato-Light'),
      url('../static/fonts/lato/Lato-Light.woff2') format('woff2'),
      url('../static/fonts/lato/Lato-Light.woff') format('woff'),
      url('../static/fonts/lato/Lato-Light.ttf') format('truetype')

@font-face
  font-family 'Lato'
  font-style normal
  font-weight 400
  src local('Lato Regular'),
      local('Lato-Regular'),
      url('../static/fonts/lato/Lato-Regular.woff2') format('woff2'),
      url('../static/fonts/lato/Lato-Regular.woff') format('woff'),
      url('../static/fonts/lato/Lato-Regular.ttf') format('truetype')

@font-face
  font-family 'Lato'
  font-style normal
  font-weight 700
  src local('Lato Bold'),
      local('Lato-Bold'),
      url('../static/fonts/lato/Lato-Bold.woff2') format('woff2'),
      url('../static/fonts/lato/Lato-Bold.woff') format('woff'),
      url('../static/fonts/lato/Lato-Bold.ttf') format('truetype')

@require './variables'
@require './resets'
@require './mixins'
@require './animations'
@require './elements/typography'
@require './elements/buttons'
@require './elements/inputs'
@require './elements/calendars'
@require './elements/progress'
@require './elements/lists'
