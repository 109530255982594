list-stripe-color = gray0
list-border-color = gray2

.list
  background white

.list--bordered
  border 1px solid list-border-color

.list--scrollable
  overflow auto

.list--lined
  > :not(:last-child)
    border-bottom 1px solid gray2

.list--hoverable
  > *
    transition background 0.2s ease

    &:hover
      background blue0
