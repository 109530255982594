.drivers
  flexx(column)
  width 100%
  height 100%
  box-sizing border-box
  padding 0.5rem
  min-height 0
  background white

.drivers__alert
  position absolute
  right 20px // This value must match the MARGINS.top value in ScatterPlot.js

.drivers__plot
  flex 1
  min-height 0
  position relative
  margin-bottom 0.5rem
  padding-top 0
  box-sizing border-box

.drivers__filter-warning
  width 80%
  max-width 28rem
  height 10rem
  padding 3.5rem 1.8rem

.drivers__loading
  flexx(column)
  align-items center
  p
    margin-top 0.5rem

.drivers-tooltip-content__row:not(:last-child)
  margin-bottom 1rem

.drivers-tooltip-content__header
  color textLight
