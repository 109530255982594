@import '~normalize.css/normalize.css';
@import '~font-awesome/css/font-awesome.min.css';
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url("../static/fonts/lato/Lato-Light.woff2") format('woff2'), url("../static/fonts/lato/Lato-Light.woff") format('woff'), url("../static/fonts/lato/Lato-Light.ttf") format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url("../static/fonts/lato/Lato-Regular.woff2") format('woff2'), url("../static/fonts/lato/Lato-Regular.woff") format('woff'), url("../static/fonts/lato/Lato-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url("../static/fonts/lato/Lato-Bold.woff2") format('woff2'), url("../static/fonts/lato/Lato-Bold.woff") format('woff'), url("../static/fonts/lato/Lato-Bold.ttf") format('truetype');
}
ul,
menu,
dir {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}
ul {
  margin: 0;
}
.err-text {
  color: #ef6867;
  font-size: 0.875rem;
  font-style: italic;
}
.loading-stripes {
  -webkit-transition: opacity 0.75s ease-in-out;
  -moz-transition: opacity 0.75s ease-in-out;
  -o-transition: opacity 0.75s ease-in-out;
  -ms-transition: opacity 0.75s ease-in-out;
  transition: opacity 0.75s ease-in-out;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 60px 60px;
  -webkit-animation: animate-stripes 2s linear infinite;
  -moz-animation: animate-stripes 2s linear infinite;
  -o-animation: animate-stripes 2s linear infinite;
  -ms-animation: animate-stripes 2s linear infinite;
  animation: animate-stripes 2s linear infinite;
  background-image: -webkit-linear-gradient(315deg, rgba(0,0,0,0.2) 25%, transparent 25%, transparent 50%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.2) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(315deg, rgba(0,0,0,0.2) 25%, transparent 25%, transparent 50%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.2) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(315deg, rgba(0,0,0,0.2) 25%, transparent 25%, transparent 50%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.2) 75%, transparent 75%, transparent);
  background-image: -ms-linear-gradient(315deg, rgba(0,0,0,0.2) 25%, transparent 25%, transparent 50%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.2) 75%, transparent 75%, transparent);
  background-image: linear-gradient(135deg, rgba(0,0,0,0.2) 25%, transparent 25%, transparent 50%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.2) 75%, transparent 75%, transparent);
}
.loading-stripes.visible {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.fade-enter {
  opacity: 0.01;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  filter: alpha(opacity=1);
}
.fade-enter-active {
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.fade-appear {
  opacity: 0.01;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  filter: alpha(opacity=1);
}
.fade-appear-active {
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.fade-exit {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.fade-exit-active {
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  opacity: 0.01;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  filter: alpha(opacity=1);
}
@-moz-keyframes animate-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -60px 0;
  }
}
@-webkit-keyframes animate-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -60px 0;
  }
}
@-o-keyframes animate-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -60px 0;
  }
}
@keyframes animate-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -60px 0;
  }
}
html {
  color: #2c4249;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
}
@media (max-width: 900px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  html {
    font-size: 12px;
  }
}
p {
  line-height: 1.5;
  margin: 0 0 0.5em 0;
}
a {
  color: #04aade;
  text-decoration: none;
}
a:hover {
  color: #3ebde6;
}
a:active {
  color: #0396c2;
}
a:hover {
  text-decoration: underline;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0.5em 0;
}
h1,
h2,
h3 {
  font-weight: 300;
}
h6 {
  font-weight: 700;
}
h1 {
  font-size: 2.5em;
}
h2 {
  font-size: 2.25em;
}
h3 {
  font-size: 2em;
}
h4 {
  font-size: 1.5em;
}
h5 {
  font-size: 1.25em;
}
h6 {
  font-size: 1em;
}
ul,
ol {
  line-height: 1.5em;
}
.rtl {
  direction: rtl;
}
button {
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  white-space: nowrap;
  background: unset;
  border: unset;
  padding: unset;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
label {
  font-size: 0.875rem;
}
legend {
  font-size: 0.875rem;
  color: #859196;
}
input,
textarea {
  -webkit-box-shadow: inset 0 1px 3px rgba(0,0,0,0.3);
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.3);
  background-color: #fff;
  border: none;
  border-radius: 0.125rem;
  display: block;
  height: 2rem;
  margin: 0.5rem 0;
  padding: 0.25rem 0.5rem;
}
input:disabled,
textarea:disabled {
  background: #f3f4f5;
  color: #859196;
}
input.error,
textarea.error {
  border: 1px #fff1ed;
  -webkit-box-shadow: 0 0 2px 2px #fff1ed;
  box-shadow: 0 0 2px 2px #fff1ed;
}
.label {
  color: #859196;
  font-size: 0.875rem;
  margin: 0.5rem;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.calendar {
  display: inline-block;
}
.calendar__current-month {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  text-align: center;
  cursor: default;
  height: 2rem;
}
.calendar__week-name {
  text-align: center;
  font-size: 0.875rem;
  height: 1.5rem;
}
.calendar__row {
  height: 2rem;
}
.calendar__cell {
  border: 1px #c7cdcf solid;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.calendar__cell:hover {
  border: 1px #1ba3cf double;
}
.calendar__cell--different-month {
  border: none;
}
.calendar__cell--different-month:hover {
  border: none;
}
.calendar__cell--selected {
  border: 1px #0388b2 double;
}
.calendar__cell--inside-selected-range {
  border: 1px #0499c8 double;
}
.calendar__cell--disabled {
  pointer-events: none;
}
.calendar__day-container {
  position: relative;
}
.calendar__day-container--start:after,
.calendar__day-container--end:after {
  z-index: 1;
  content: '';
  position: absolute;
  margin-top: -1px;
  width: 0.125rem;
  height: 100%;
  background: #0388b2;
  border: 1px #0388b2 double;
}
.calendar__day-container--start:after {
  left: 0;
  margin-left: -0.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.calendar__day-container--end:after {
  right: 0;
  margin-right: -0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.calendar__day {
  position: relative;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 2rem;
  width: 2rem;
  text-align: center;
}
.calendar__day:hover {
  background: #3ebde6;
  color: #fff;
}
.calendar__day:disabled,
.calendar__day:disabled:hover {
  background: #f3f4f5;
  color: #859196;
}
.calendar__day--selected {
  background: #04aade;
  color: #fff;
}
.calendar__day--inside-selected-range {
  background: #58d5fc;
}
.lumi-progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border: 1px solid #2c4249;
  border-radius: 0.1875rem;
  height: 1.5rem;
}
.lumi-progress::-webkit-progress-bar {
  background: #fff;
  border-radius: 0.1875rem;
}
.lumi-progress::-webkit-progress-value {
  background: #04aade;
  border-top-left-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px);
}
.lumi-progress::-moz-progress-bar {
  background: #04aade;
  border-top-left-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px);
}
.list {
  background: #fff;
}
.list--bordered {
  border: 1px solid #c7cdcf;
}
.list--scrollable {
  overflow: auto;
}
.list--lined > :not(:last-child) {
  border-bottom: 1px solid #c7cdcf;
}
.list--hoverable > * {
  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
  -ms-transition: background 0.2s ease;
  transition: background 0.2s ease;
}
.list--hoverable > *:hover {
  background: #ebf5ff;
}
html,
body {
  height: 100%;
  color: #2c4249 !important;
  font-family: 'Lato', Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: inherit !important;
  margin: inherit !important;
  letter-spacing: inherit !important;
  background-color: inherit !important;
  line-height: inherit !important;
}
.global-error {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.app-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 350px;
}
.concept-cloud-wrapper {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  position: relative;
}
.concept-cloud-wrapper .cloud {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.concept-cloud-wrapper .cloud .concept {
  cursor: pointer;
  -webkit-transition-property: font-size;
  -moz-transition-property: font-size;
  -o-transition-property: font-size;
  -ms-transition-property: font-size;
  transition-property: font-size;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  -ms-transition-duration: 1s;
  transition-duration: 1s;
}
.concept-cloud {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: 2rem;
}
.sentiment-table {
  -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  border-radius: 0.1875rem;
  overflow: hidden;
}
.sentiment-table--list {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
}
.sentiment-table--list > :not(:first-child) {
  margin-top: 0.5rem;
}
.sentiment-table__match-percentage {
  color: #859196;
}
.sentiment-table__share-charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.125rem;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.galaxy-work-area {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 0.5rem;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.galaxy-work-area .header {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -o-box-flex: 0;
  -ms-box-flex: 0;
  box-flex: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
}
.galaxy-work-area .footer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}
.galaxy-work-area .footer > * {
  margin-right: 0.5rem;
}
.galaxy-work-area .footer .cloud-axis-selector {
  position: relative;
}
.galaxy-work-area .footer .cloud-axis-selector .active-concept-selector {
  -webkit-box-shadow: 0 3px 9px rgba(0,0,0,0.5);
  box-shadow: 0 3px 9px rgba(0,0,0,0.5);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 0.125em;
  position: absolute;
  bottom: 2rem;
  margin-bottom: 0.5rem;
  height: 300px;
  max-width: 16rem;
}
.galaxy-work-area .footer .cloud-axis-selector .active-concept-selector .active-concept-selector-header {
  padding: 0.5rem;
  -webkit-flex-basis: auto;
  flex-basis: auto;
  border-bottom: 1px solid #c7cdcf;
}
.galaxy-work-area .footer .cloud-axis-selector .active-concept-selector .active-concept-selector-header > .fuzzy-search-box {
  margin: 0;
  min-width: 180px;
}
.galaxy-work-area .footer .cloud-axis-selector .active-concept-selector .active-concept-selector-list {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.galaxy-work-area .footer .cloud-axis-selector .active-concept-selector .active-concept-selector-selection-name:hover {
  cursor: text;
}
.concept-search > input {
  margin: 0;
}
.cloud-axis-btn {
  display: inline-block;
  max-width: 16rem;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.cloud-axis-btn:disabled {
  pointer-events: none;
}
.axis-control__btn svg,
.cloud-axis-btn svg {
  vertical-align: bottom;
}
.galaxy-work-area__cloud-message {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.work-area-action-bar-item {
  display: inline-block;
  vertical-align: top;
}
.work-area-action-bar-item + .work-area-action-bar-item {
  margin-left: 0.5rem;
}
.doc-text__match {
  background-size: 0.4rem 2px;
  background-position: 0 100%;
  background-repeat: repeat-x;
  display: inline;
  font-weight: bold;
  padding: 1px 0;
}
.doc-text__match--conceptual {
  background-image: -webkit-linear-gradient(left, rgba(3,150,194,0.8) 60%, rgba(3,150,194,0) 60%);
  background-image: -moz-linear-gradient(left, rgba(3,150,194,0.8) 60%, rgba(3,150,194,0) 60%);
  background-image: -o-linear-gradient(left, rgba(3,150,194,0.8) 60%, rgba(3,150,194,0) 60%);
  background-image: -ms-linear-gradient(left, rgba(3,150,194,0.8) 60%, rgba(3,150,194,0) 60%);
  background-image: linear-gradient(to right, rgba(3,150,194,0.8) 60%, rgba(3,150,194,0) 60%);
}
.doc-text__match--exact {
  background-image: -webkit-linear-gradient(left, rgba(3,150,194,0.8) 0%, rgba(3,150,194,0.8) 100%);
  background-image: -moz-linear-gradient(left, rgba(3,150,194,0.8) 0%, rgba(3,150,194,0.8) 100%);
  background-image: -o-linear-gradient(left, rgba(3,150,194,0.8) 0%, rgba(3,150,194,0.8) 100%);
  background-image: -ms-linear-gradient(left, rgba(3,150,194,0.8) 0%, rgba(3,150,194,0.8) 100%);
  background-image: linear-gradient(to right, rgba(3,150,194,0.8) 0%, rgba(3,150,194,0.8) 100%);
}
.explorer-subnavigation {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-right: 1px solid #dde0e2;
}
.explorer-subnavigation .tooltip:nth-child(6) {
  margin-top: auto;
  margin-bottom: 0.5rem;
}
.explorer-subnavigation__link {
  display: block;
  margin-top: 0.5rem;
  padding: 0 0.3125rem;
  border-left: 0.375rem solid transparent;
  border-right: 0.375rem solid transparent;
}
.explorer-subnavigation__link:hover .explorer-subnavigation__icon {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.explorer-subnavigation__link--active {
  border-left-color: #04aade;
}
.explorer-subnavigation__link--active .explorer-subnavigation__icon {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.explorer-subnavigation__icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  display: block;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  -webkit-transition: fill 0.2s ease, opacity 0.2s ease;
  -moz-transition: fill 0.2s ease, opacity 0.2s ease;
  -o-transition: fill 0.2s ease, opacity 0.2s ease;
  -ms-transition: fill 0.2s ease, opacity 0.2s ease;
  transition: fill 0.2s ease, opacity 0.2s ease;
}
.filter-selector {
  padding: 0.25rem 0;
  border-bottom: 1px solid #c7cdcf;
}
.filter-selector__flyout {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.filter-selector__fields {
  margin-left: 0.875rem;
  width: 12rem;
}
.filter-selector__field-description {
  color: #859196;
}
.filter-selector__ctrls {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -o-box-lines: single;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  padding-right: 1rem;
}
.filter-selector__input-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-shadow: inset 0 1px 3px rgba(0,0,0,0.3);
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.3);
  margin: 0.5rem 0;
  border-radius: 0.125rem;
}
.filter-selector__input-container--invalid {
  border: 1px solid #ef6867;
  -webkit-box-shadow: 0 0 5px #ef6867;
  box-shadow: 0 0 5px #ef6867;
}
.filter-selector__container {
  margin: 0.5rem;
  margin-top: 0;
}
.filter-selector__container label:first-of-type {
  margin-top: 0;
}
.filter-selector__label {
  display: block;
  margin: 0 0.5rem;
}
.filter-selector__range-input {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}
.filter-selector__label--categorical {
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: 0.875rem;
  margin: 0;
  padding: 0.25rem 0.5rem;
}
.filter-selector__label--categorical > *:first-child {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.filter-selector__label--categorical > *:last-child {
  margin-left: 0.5rem;
}
.filter-selector__over-category-limit-msg {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: 0.875rem;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0.5rem 0.25rem 0.25rem;
}
.filter-tool {
  height: 100%;
  min-height: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.filter-tool__body {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.fuzzy-search-box {
  position: relative;
}
.fuzzy-search-box > input {
  width: 100%;
  padding: 0 2.5rem 0 0.5rem;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1rem;
}
.fuzzy-search-box > svg {
  position: absolute;
  top: 0.5rem;
  right: 0.75rem;
  font-size: 1em;
  color: #04aade;
  cursor: pointer;
}
.fuzzy-search-box > svg:hover {
  color: #3ebde6;
}
.fuzzy-search-box > svg.disabled {
  cursor: auto;
  color: #b1b9bc;
}
.notification-toast {
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.2s linear 0.3s, visibility 0s linear 0.5s;
  -moz-transition: opacity 0.2s linear 0.3s, visibility 0s linear 0.5s;
  -o-transition: opacity 0.2s linear 0.3s, visibility 0s linear 0.5s;
  -ms-transition: opacity 0.2s linear 0.3s, visibility 0s linear 0.5s;
  transition: opacity 0.2s linear 0.3s, visibility 0s linear 0.5s;
}
.notification-toast--is-active {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  -ms-transition-delay: 0s;
  transition-delay: 0s;
}
.project-brancher__field-container {
  margin: 1rem 0;
}
.project-brancher__field-container--inline {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.project-brancher__label-container {
  margin: 0;
  margin-bottom: 0.5rem;
}
.project-brancher__label-container--inline {
  margin-bottom: 0;
  margin-left: 0.25rem;
}
.project-brancher__label {
  display: block;
  font-size: 1rem;
  margin: 0;
}
.project-brancher__label--required:after {
  content: '*';
  color: #ef6867;
}
.project-brancher__field--textarea,
.project-brancher__field--text {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.project-brancher__field--textarea::placeholder,
.project-brancher__field--text::placeholder {
  color: #859196;
  font-style: italic;
}
.project-brancher__field--textarea {
  height: 4rem;
  padding: 0.5625rem;
  resize: none;
}
.project-brancher__field--invalid {
  border: 1px solid #ef6867;
  -webkit-box-shadow: 0 0 5px #ef6867;
  box-shadow: 0 0 5px #ef6867;
}
.project-brancher__doc-count {
  color: #859196;
  font-style: italic;
}
.project-brancher__project-list-link {
  -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  border-radius: 0.1875rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ebf5ff;
  color: #2c4249;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  height: 2rem;
  padding: 0.5rem;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  white-space: nowrap;
  height: unset;
  margin-right: 0.25rem;
}
.project-brancher__project-list-link:hover,
.project-brancher__project-list-link:active,
.project-brancher__project-list-link.active {
  text-decoration: none;
  background: #b1e2f7;
  color: #2c4249;
}
.project-brancher__project-list-link.cancel,
.project-brancher__project-list-link.delete {
  background-color: #ea3a3a;
}
.project-brancher__project-list-link.cancel:hover,
.project-brancher__project-list-link.delete:hover {
  background-color: #ef6867;
}
.project-brancher__project-list-link.create,
.project-brancher__project-list-link.add {
  background-color: #85b444;
}
.project-brancher__project-list-link.create:hover,
.project-brancher__project-list-link.add:hover {
  background-color: #95ca4e;
}
.project-brancher__project-list-link.alert,
.project-brancher__project-list-link.change {
  padding: 0 0.5rem;
  background-color: #c8ac25;
}
.project-brancher__project-list-link.alert:hover,
.project-brancher__project-list-link.change:hover {
  background-color: #e0c22b;
}
.project-brancher__project-list-link:disabled,
.project-brancher__project-list-link.disabled {
  background: #f3f4f5;
  color: #859196;
}
.project-brancher__project-list-link:disabled:hover,
.project-brancher__project-list-link.disabled:hover {
  background: #f3f4f5;
  color: #859196;
}
.project-brancher__project-list-link:hover {
  color: #fff;
}
.project-brancher__required-explanation {
  color: #859196;
  font-style: italic;
  margin-top: 0.5rem;
}
.project-brancher__required-explanation:before {
  content: '*';
  color: #ef6867;
}
.project-error-modal__header {
  font-weight: 600;
}
.project-error-modal__body {
  margin-bottom: 1rem;
}
.project-error-modal__footer {
  text-align: left;
}
.project-error-modal__link {
  -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  border-radius: 0.1875rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ebf5ff;
  color: #2c4249;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  height: 2rem;
  padding: 0.5rem;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  white-space: nowrap;
  height: unset;
  margin-right: 0.5rem;
}
.project-error-modal__link:hover,
.project-error-modal__link:active,
.project-error-modal__link.active {
  text-decoration: none;
  background: #b1e2f7;
  color: #2c4249;
}
.project-error-modal__link.cancel,
.project-error-modal__link.delete {
  background-color: #ea3a3a;
}
.project-error-modal__link.cancel:hover,
.project-error-modal__link.delete:hover {
  background-color: #ef6867;
}
.project-error-modal__link.create,
.project-error-modal__link.add {
  background-color: #85b444;
}
.project-error-modal__link.create:hover,
.project-error-modal__link.add:hover {
  background-color: #95ca4e;
}
.project-error-modal__link.alert,
.project-error-modal__link.change {
  padding: 0 0.5rem;
  background-color: #c8ac25;
}
.project-error-modal__link.alert:hover,
.project-error-modal__link.change:hover {
  background-color: #e0c22b;
}
.project-error-modal__link:disabled,
.project-error-modal__link.disabled {
  background: #f3f4f5;
  color: #859196;
}
.project-error-modal__link:disabled:hover,
.project-error-modal__link.disabled:hover {
  background: #f3f4f5;
  color: #859196;
}
.project-error-modal__link:hover {
  color: #fff;
}
.project-explorer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
}
.project-explorer__tool {
  position: relative;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
}
.project-explorer__notification {
  display: inline;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1000;
}
.scatter-plot {
  vertical-align: top;
  display: block;
}
.scatter-plot__label {
  font-size: 0.875rem;
}
.scatter-plot-grid__line {
  stroke: #dde0e2;
  stroke-width: 1;
}
.scatter-plot-grid__line--dashed {
  stroke: #dde0e2;
  stroke-dasharray: 1 10;
  stroke-width: 3;
  stroke-linecap: round;
}
.scatter-plot__axis-label {
  font-size: 0.875rem;
  font-weight: bold;
}
.scatter-plot__datapoint-tip {
  cursor: pointer;
}
.scatter-plot__datapoint-tip--selected text,
.scatter-plot__datapoint-tip:hover text {
  font-weight: bold;
}
.drivers {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.5rem;
  min-height: 0;
  background: #fff;
}
.drivers__alert {
  position: absolute;
  right: 20px;
}
.drivers__plot {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
  position: relative;
  margin-bottom: 0.5rem;
  padding-top: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.drivers__filter-warning {
  width: 80%;
  max-width: 28rem;
  height: 10rem;
  padding: 3.5rem 1.8rem;
}
.drivers__loading {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.drivers__loading p {
  margin-top: 0.5rem;
}
.drivers-tooltip-content__row:not(:last-child) {
  margin-bottom: 1rem;
}
.drivers-tooltip-content__header {
  color: #859196;
}
.suggested-concepts {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}
.suggested-concepts .tooltip {
  display: block;
}
.suggested-concepts__header {
  padding: 0.5em;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  border-bottom: 1px solid #dde0e2;
}
.suggested-concepts__subheader {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dde0e2;
}
.suggested-concepts__subheader label {
  margin-left: 0.5rem;
}
.suggested-concepts__footer {
  padding: 0.5em;
}
.suggested-concepts__body {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 2rem;
}
.suggested-concepts__add-btn,
.suggested-concepts__close-btn {
  width: 100%;
}
.suggested-concepts__subheader,
.suggestion {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-left: 0.5rem;
}
.suggested-concepts__subheader .lumi-checkbox,
.suggestion .lumi-checkbox {
  margin-top: 0.1rem;
}
.suggestion__checkbox-label {
  margin: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
  display: inline-block;
}
.suggestion__concept {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  vertical-align: top;
}
.upload-page {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 2rem;
}
.upload-page__preview-section {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.upload-page__preview {
  -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  border-radius: 0.1875rem;
  position: relative;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.upload-page__field--file {
  display: none;
}
.upload-page__field--button {
  -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  border-radius: 0.1875rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ebf5ff;
  color: #2c4249;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  height: 2rem;
  padding: 0.5rem;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  white-space: nowrap;
  padding: 0.5rem;
  height: unset;
}
.upload-page__field--button:hover,
.upload-page__field--button:active,
.upload-page__field--button.active {
  text-decoration: none;
  background: #b1e2f7;
  color: #2c4249;
}
.upload-page__field--button.cancel,
.upload-page__field--button.delete {
  background-color: #ea3a3a;
}
.upload-page__field--button.cancel:hover,
.upload-page__field--button.delete:hover {
  background-color: #ef6867;
}
.upload-page__field--button.create,
.upload-page__field--button.add {
  background-color: #85b444;
}
.upload-page__field--button.create:hover,
.upload-page__field--button.add:hover {
  background-color: #95ca4e;
}
.upload-page__field--button.alert,
.upload-page__field--button.change {
  padding: 0 0.5rem;
  background-color: #c8ac25;
}
.upload-page__field--button.alert:hover,
.upload-page__field--button.change:hover {
  background-color: #e0c22b;
}
.upload-page__field--button:disabled,
.upload-page__field--button.disabled {
  background: #f3f4f5;
  color: #859196;
}
.upload-page__field--button:disabled:hover,
.upload-page__field--button.disabled:hover {
  background: #f3f4f5;
  color: #859196;
}
.upload-page__result-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 50%;
  text-align: center;
}
.upload-page__result-msg {
  margin: 0.5rem 0;
  font-size: 0.875rem;
}
.upload-page__result-msg--header {
  font-size: 1rem;
}
.upload-page__result-msg--footer {
  color: #859196;
  font-style: italic;
}
.upload-preview {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 0.5rem;
  overflow: scroll;
}
.upload-preview__message-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 20rem;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.upload-preview__message {
  color: #859196;
  font-style: italic;
}
.upload-preview__metadata-value {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.upload-preview__metadata-value:not(:last-child) {
  margin-bottom: 0.25rem;
}
.vertical-bar-chart {
  display: block;
  width: 100%;
  height: 2rem;
}
.vertical-bar-chart__bar {
  fill: #78d0ef;
}
.vertical-bar-chart__bar:last-child {
  fill: #04aade;
}
.workspace {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -o-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  min-height: 500px;
  min-width: 720px;
}
.workspace__sidebar,
.workspace__work-area {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0.5rem;
}
.workspace__sidebar {
  width: 27%;
  vertical-align: top;
  border-radius: 0.125em;
  min-width: 393px;
}
.workspace__sidebar > * {
  border-radius: 0.125rem;
}
.workspace__sidebar > :not(:last-child) {
  margin-bottom: 0.5rem;
}
@media (max-width: 900px) {
  .workspace__sidebar {
    min-width: 262px;
  }
}
.workspace__work-area {
  min-width: 262px;
  -webkit-box-flex: 3;
  -moz-box-flex: 3;
  -o-box-flex: 3;
  -ms-box-flex: 3;
  box-flex: 3;
  -webkit-flex-grow: 3;
  flex-grow: 3;
  -webkit-flex-shrink: 3;
  flex-shrink: 3;
  min-width: 0;
}
