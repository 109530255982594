.suggested-concepts
  flexx(column)
  height 100%
  min-height 0

  .tooltip
    display block

.suggested-concepts__header
  padding 0.5em
  margin-bottom 0.5rem
  font-size 0.875rem
  border-bottom: 1px solid gray1;

.suggested-concepts__subheader
  padding-bottom 0.5rem
  border-bottom 1px solid gray1
  label
    margin-left 0.5rem

.suggested-concepts__footer
  padding 0.5em

.suggested-concepts__body
  flex 1
  min-height 2rem

.suggested-concepts__add-btn
.suggested-concepts__close-btn
  width 100%

.suggested-concepts__subheader
.suggestion
  flexx(row)
  align-items center
  padding-left .5rem

  .lumi-checkbox
    margin-top .1rem

.suggestion__checkbox-label
  margin 0
  height 1px
  width 1px
  overflow hidden
  display inline-block

.suggestion__concept
  flex auto
  overflow ellipsis
  vertical-align top
