// requisite resets not covered by normalize.css

ul, menu, dir
  -webkit-margin-before 0
  -webkit-margin-after 0
  -webkit-margin-start 0
  -webkit-margin-end 0

ul
  margin 0  // Firefox
