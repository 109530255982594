icon_dimension = 2rem
active-bar-width = 0.375rem

.explorer-subnavigation
  flexx(column)
  background white
  border-right 1px solid gray1
  // Bottom align "Project management" icon
  .tooltip:nth-child(6)
    margin-top auto
    margin-bottom 0.5rem

.explorer-subnavigation__link
  display block
  margin-top 0.5rem
  padding 0 (0.6875rem - active-bar-width)
  border-left active-bar-width solid transparent
  border-right active-bar-width solid transparent

  &:hover
    .explorer-subnavigation__icon
      opacity 1

.explorer-subnavigation__link--active
  border-left-color standardBtn

  .explorer-subnavigation__icon
    opacity 1

.explorer-subnavigation__icon
  square(icon_dimension)
  padding 0.5rem
  display block
  opacity 0.5
  transition fill 0.2s ease, opacity 0.2s ease
