.sentiment-table
  outset-shadow()
  rounded-corners()
  overflow hidden

.sentiment-table--list
  flex 1
  min-height 0
  > :not(:first-child)
    margin-top workspace-gutter

.sentiment-table__match-percentage
  color textLight

.sentiment-table__share-charts
  display grid
  grid-template-columns 1fr 1fr
  gap 0.125rem
  align-items center
  text-align center
  flex 1
