.lumi-progress
  borderThickness = 1px
  outerRadius = standardRadius
  // avoid gap between the border and the inner bar
  innerRadius = 'calc(%s - %s)' % (standardRadius borderThickness)
  appearance none
  background white
  border borderThickness solid gray9
  border-radius outerRadius
  height 1.5rem

  &::-webkit-progress-bar
    background white
    border-radius outerRadius

  &::-webkit-progress-value
    background blue4
    border-top-left-radius innerRadius
    border-bottom-left-radius innerRadius

  &::-moz-progress-bar
    background blue4
    border-top-left-radius innerRadius
    border-bottom-left-radius innerRadius
