.workspace
  fill-viewport()
  flexx(row)
  align-items stretch
  min-height 500px
  min-width 720px

.workspace__sidebar
.workspace__work-area
  flexx(column)
  margin workspace-gutter

.workspace__sidebar
  width 27%
  vertical-align top
  border-radius .125em
  min-width 393px

  > *
    border-radius .125rem

  > :not(:last-child)
    margin-bottom workspace-gutter

@media (max-width: 900px)
  .workspace__sidebar
    min-width 262px

.workspace__work-area
  min-width 262px
  flex-grow 3
  flex-shrink 3
  min-width 0
