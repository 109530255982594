html
  color textDark
  font-family baseFontFamily
  font-size baseFontSize
  -moz-osx-font-smoothing grayscale // fix chunky text rendering in FF on OSX
  -webkit-font-smoothing antialiased
  font-weight normal

@media (max-width: 900px)
  html
    font-size 14px

@media (max-width: 600px)
  html
    font-size 12px

p
  line-height 1.5
  margin 0 0 .5em 0

a
  link-color()
  link-text-decoration()

h1, h2, h3, h4, h5, h6
  margin 0
  padding baseMargin

h1, h2, h3
  font-weight 300

h6
  font-weight 700

h1
  font-size 2.5em

h2
  font-size 2.25em

h3
  font-size 2em

h4
  font-size 1.5em

h5
  font-size 1.25em

h6
  font-size 1em

ul, ol
  line-height 1.5em

.rtl
  direction rtl
