.project-brancher__field-container
  margin 1rem 0

.project-brancher__field-container--inline
  flexx(row)

.project-brancher__label-container
  margin 0
  margin-bottom 0.5rem

.project-brancher__label-container--inline
  margin-bottom 0
  margin-left 0.25rem

.project-brancher__label
  display block
  font-size 1rem
  margin 0

.project-brancher__label--required
  &:after
    content '*'
    color errorTxt

.project-brancher__field--textarea
.project-brancher__field--text
  width 100%
  box-sizing border-box

  &::placeholder
    hint-text()

.project-brancher__field--textarea
  height 4rem
  padding 0.5625rem
  resize none

.project-brancher__field--invalid
  error-outline()

.project-brancher__doc-count
  hint-text()

.project-brancher__project-list-link
  button()
  height unset
  margin-right 0.25rem

.project-brancher__project-list-link:hover
  color white

.project-brancher__required-explanation
  hint-text()
  margin-top 0.5rem

  &:before
    content '*'
    color errorTxt
