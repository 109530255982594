.scatter-plot
  // Fix sneaky vertical scrollbar that appears due to 100% height
  vertical-align top
  display block // Fix 100% height issue in Safari

.scatter-plot__label
  font-size 0.875rem

.scatter-plot-grid__line
  stroke gray1
  stroke-width 1

.scatter-plot-grid__line--dashed
  stroke gray1
  stroke-dasharray 1 10
  stroke-width 3
  stroke-linecap round

.scatter-plot__axis-label
  font-size 0.875rem
  font-weight bold

.scatter-plot__datapoint-tip
  cursor pointer

.scatter-plot__datapoint-tip--selected
.scatter-plot__datapoint-tip:hover
  text
    font-weight bold

