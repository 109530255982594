.concept-cloud-wrapper
  flex-grow 1
  position relative

  .cloud
    fill-viewport()

    .concept
      cursor pointer
      // The font-size changes when changing the number of concepts being
      // displayed. Add a transition so that changing the font-size doesn't
      // look choppy.
      transition-property: font-size;
      transition-duration: 1s;


.concept-cloud
  fill-viewport()
  bottom 2rem
