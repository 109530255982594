@require './core/core'

html, body
  height 100%
  color textDark!important
  font-family baseFontFamily!important
  font-size baseFontSize!important
  -moz-osx-font-smoothing grayscale // fix chunky text rendering in FF on OSX
  -webkit-font-smoothing antialiased
  font-weight inherit!important
  margin inherit!important
  letter-spacing inherit!important
  background-color inherit!important
  line-height inherit!important

.global-error
  center()

.app-container
  fill-viewport()
  flexx(column)
  min-height 350px
