.project-explorer
  flexx(row)
  flex 1
  min-height 0

.project-explorer__tool
  position relative
  flex 1
  min-width 0

.project-explorer__notification
  display inline
  position absolute
  top workspace-gutter
  right workspace-gutter
  z-index zIndexFlyout // make sure it sits over the header
