.galaxy-work-area
  flexx(column)
  margin-bottom workspace-gutter
  flex 1

  .header
    flex-grow 0

  .footer
    flexx(row)
    position relative
    bottom 0
    left 0
    right 0

    > *
      margin-right workspace-gutter

    .cloud-axis-selector
      position relative

      .active-concept-selector
        outset-shadow-heavy()
        flexx(column)
        background white
        border-radius .125em
        position absolute
        bottom 2rem
        margin-bottom 0.5rem
        height 300px
        max-width 16rem

        .active-concept-selector-header
          padding .5rem
          flex-basis auto
          border-bottom: 1px solid gray2

          > .fuzzy-search-box
            margin 0
            min-width 180px

        .active-concept-selector-list
          flex 1

        .active-concept-selector-selection-name
          &:hover
            cursor text

// this is to get around core-ui input styles which specify the margins on input elements
.concept-search > input
  margin 0

.cloud-axis-btn
  display inline-block
  max-width 16rem
  overflow ellipsis
  // Allows tooltip to close when button is disabled
  &:disabled
    pointer-events none

.axis-control__btn
.cloud-axis-btn
  svg
    // By default, our inline SVG icons will align with the baseline of the text
    // rather than the bottom of the text. If a button has no text, then the
    // icon will be the only thing determining the height of the button.
    // This will make buttons with text slightly taller than buttons without text.
    // Making the icons align with the bottom of the text instead of the
    // baseline fixes this.
    vertical-align bottom

.galaxy-work-area__cloud-message
  center()

.work-area-action-bar-item
  display inline-block
  vertical-align top

  & + &
    margin-left workspace-gutter
